import React, { useEffect } from 'react'
import { useState } from 'react';
import { insertScoreEdit, updateHoleScore, getLayoutHoleId,createHoleScore, updateThrowScore } from '../util/db';
import { useAuth } from '../util/auth.js';
import { useRouter } from '../util/router';
import ScorecardRowPlayerTd from './ScorecardRowPlayerTd.js';
import ScorecardRowHoleinfoToast from './ScorecardRowHoleinfoToast.js';
import ScorecardThrowRowScoreboxTd from './ScorecardThrowRowScoreboxTd.js';
import ScorecardThrowRowScoreBox from './ScorecardThrowRowScoreBox.js';

function ScorecardThrowRow({player, currentHole, onUpdateHoleScore, isComplete, showTotals}) {

  //get throwscores for this hole
  let throwScores = player.event_entries.hole_scores.filter((hole_score) => hole_score.layout_holes.hole_order === currentHole)[0].throw_scores
  // order throwscores by throw_order
  throwScores.sort((a,b) => (a.throw_number > b.throw_number) ? 1 : -1)

  const router = useRouter()
  const auth = useAuth();
  const [showHoleInfo, setShowHoleInfo] = useState(false);
  const [dbResponseScore, setDbResponseScore] = useState(null)
  const [thisScore, setThisScore] = useState()
  
  const [throwOneScore, setThrowOneScore] = useState()
  const [throwTwoScore, setThrowTwoScore] = useState()
  const [throwOneThrowScoresId, setThrowOneThrowScoresId] = useState()
  const [throwTwoThrowScoresId, setThrowTwoThrowScoresId] = useState()
  const [isUpdatingScore] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const toggleHoleInfo = () => setShowHoleInfo(!showHoleInfo);

  const event_entries_id = player.event_entries_id
  const hole_scores_id=getHolescoreId()
  const uid = auth.user ? auth.user.uid : undefined;
  const currentHoleHoleScore = player.event_entries.hole_scores.filter((hole_score) => hole_score.layout_holes.hole_order === currentHole)[0]
  const gameId = player.event_entries.events.game_id

  //useffect set throw 1 and throw 2 scores to the first throw score if exists
  useEffect(() => {
    setThrowOneScore(throwScores[0].throw_score)
    setThrowTwoScore(throwScores[1].throw_score)
    setThrowOneThrowScoresId(throwScores[0].id)
    setThrowTwoThrowScoresId(throwScores[1].id)

    // eslint-disable-next-line
  }, [currentHoleHoleScore.throw_scores])

  //calculate this players total score
  let total = 0
  player.event_entries.hole_scores.forEach((score, index) => {
      total += score.score  
  }) 

  function onViewEdits() {
    //redirect to /holescoreedits
    router.push(`/holescoreedits?hole=${hole_scores_id}&event=${player.event_entries.event_id}`)
  }

  //function to log hole_score_edit into database
  function logHoleScoreEdit(hole_score_id,newScore,uid) {
    insertScoreEdit({hole_scores_id:hole_score_id,score:newScore,editor_id: uid})
  }

  function onClickLockedScore() {
    alert('This scorecard is locked. Please contact the event organizer to make changes.')
  }

  function getHolescoreId() {
    let hole_scores_id = null
    player.event_entries.hole_scores.map((hole_score, index) => {
      if (hole_score.layout_holes.hole_order === currentHole) {
        hole_scores_id = hole_score.hole_scores_id
      }
      return hole_scores_id
    })  
    return hole_scores_id
  }

  function onClickScore() {
    let newThrowScore = 0
    let newOverallScore = 0
    
    //thisscore is null, set both throws to 2 for an auto-5
    if(thisScore === null){
      newThrowScore = 2
      newOverallScore = 5
    }else if(thisScore === 5){//thisscore is 5, set both throws to null to reset to null
      newThrowScore = null
      newOverallScore = null
    }else{
      //break
      return
    }

    //update throw scores in database
    updateThrowScore(throwOneThrowScoresId,newThrowScore)
    updateThrowScore(throwTwoThrowScoresId,newThrowScore)

    //update throw scores in state
    setThrowOneScore(newThrowScore)
    setThrowTwoScore(newThrowScore)

    //need to update the object in the player object with the new throw scores
    let updatedPlayer = {...player}
    updatedPlayer.event_entries.hole_scores.forEach((hole_score,index)=>{
      if(hole_score.layout_holes.hole_order === currentHole){
        hole_score.throw_scores[0].throw_score = newThrowScore
        hole_score.throw_scores[1].throw_score = newThrowScore
        hole_score.score = newOverallScore
      }
    })
    
  }

  //useEffect to set thisScore to the current hole score
  useEffect(() => {
    function getHolescore() {
      const this_hole_score = player.event_entries.hole_scores.filter(
        (hole_score) => hole_score.layout_holes.hole_order === currentHole
      )
      //if this_hole_score[0] is not undefined, return it
      if(this_hole_score[0] !== undefined){
      return this_hole_score[0].score
      }
    }
    
    //if player.event_entries.holes_scores is not empty
    if(player.event_entries.hole_scores.length !== 0){
      setThisScore(getHolescore())  
    }else{
      console.log('error, no hole score for ', currentHole)
    }

    //reset dbResponseScore to null
    setDbResponseScore(null)

  }, [currentHole, player.event_entries.hole_scores])

  // Create a hole score if it doesn't exist (error state)
  useEffect(() => {
    if (holeScoresIdIsNull() && !isCreating) {
      setIsCreating(true);

      getLayoutHoleId(player.event_entries.event_divisions.layout_id, currentHole).then((res) => {

        const data = {
          event_entries_id: event_entries_id,
          layout_holes_id: res.layout_holes_id,
        };

        createHoleScore(data).then(() => {
          // console.log('created hole score', res);
          setIsCreating(false); // Reset the flag after creation
          window.location.reload();
        }).catch(() => {
          // console.error('Error creating hole score:', err);
          setIsCreating(false); // Reset the flag in case of error
        });
      }).catch(() => {
        // console.error('Error getting layout hole ID:', err);
        setIsCreating(false); // Reset the flag in case of error
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hole_scores_id, currentHole, event_entries_id]);

  function holeScoresIdIsNull() {
    return hole_scores_id === null;
  }



  //if throwonescore or throwtwoscore change, update thisscore and hole score in database
  useEffect(() => {

    //if throwonescore or throwtwoscore are not null, set thisscore to the sum of the two
    if (throwOneScore !== null || throwTwoScore !== null) {
      let newScore = (throwOneScore !== null ? Number(throwOneScore) : 0) + (throwTwoScore !== null ? Number(throwTwoScore) : 0);

      // if newscore === 4, set it to 5
      if(newScore === 4){
        newScore = 5
      }

      setThisScore(newScore);
      updateHoleScore(hole_scores_id, event_entries_id, { score: newScore }).then((res) => {
        setDbResponseScore(res[0].score);
      });
      onUpdateHoleScore(hole_scores_id, event_entries_id, newScore);
      logHoleScoreEdit(hole_scores_id, newScore, uid);
    }

    //if throwonescore and throwtwoscore are null, set thisscore to null
    if(throwOneScore === null && throwTwoScore === null){
      let newScore = null
      setThisScore(newScore)
      updateHoleScore(hole_scores_id,event_entries_id,{score: newScore}).then((res)=>{
        setDbResponseScore(res[0].score)
      })
      onUpdateHoleScore(hole_scores_id,event_entries_id,newScore)
      logHoleScoreEdit(hole_scores_id,newScore,uid)
    }

    //update the player object with the new throw scores
    let updatedPlayer = {...player}

    updatedPlayer.event_entries.hole_scores.forEach((hole_score,index)=>{
      if(hole_score.layout_holes.hole_order === currentHole){
        throwScores[0].throw_score = throwOneScore
        throwScores[1].throw_score = throwTwoScore
      }
    })
       
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [throwOneScore,throwTwoScore])




  return (
    <>
      <tr>
        <ScorecardRowPlayerTd playerName={player.event_entries.users_public.name} showTotals={showTotals} total={total} toggleHoleInfo={toggleHoleInfo} />
        <ScorecardThrowRowScoreBox isComplete={isComplete} gameId={gameId} currentHoleHoleScore={currentHoleHoleScore} throwScore={throwOneScore} setThrowScore={setThrowOneScore} throwScoresId={throwOneThrowScoresId}/>
        <ScorecardThrowRowScoreBox isComplete={isComplete} gameId={gameId} currentHoleHoleScore={currentHoleHoleScore} throwScore={throwTwoScore} setThrowScore={setThrowTwoScore} throwScoresId={throwTwoThrowScoresId} />
        <ScorecardThrowRowScoreboxTd isUpdatingScore={isUpdatingScore} isComplete={isComplete} thisScore={thisScore} hole_scores_id={hole_scores_id} dbResponseScore={dbResponseScore}
          onClickScore={onClickScore} 
          onClickLockedScore={onClickLockedScore} 
        />
      </tr>
      <tr>
        <ScorecardRowHoleinfoToast player={player} currentHole={currentHole} showHoleInfo={showHoleInfo} toggleHoleInfo={toggleHoleInfo} onViewEdits={onViewEdits} />
      </tr>
    </>
  )
}

export default ScorecardThrowRow