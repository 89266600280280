import React from 'react'
import SectionHeader from './SectionHeader'
import EventCreateChooseDivisions from './EventCreateChooseDivisions'

function EventCreateFormStep2({ divisions, divisionsStatus, selectedDivisions, setSelectedDivisions }) {
  return (
    <>
        <SectionHeader
            title={'Divisions'}
            subtitle={'Please choose divisions for this event'}
            size={1}
            spaced={true}
            className="text-center mt-3 mb-3"
        />

        <div className='mb-2'>
            {divisionsStatus === 'loading' && (
                <div>Loading...</div>
            )}
            {divisionsStatus === 'error' && (
                <div>Error...</div>
            )}
            {divisionsStatus === 'success' && (
                <EventCreateChooseDivisions divisions={divisions} selectedDivisions={selectedDivisions} setSelectedDivisions={setSelectedDivisions}/>
            )}
        </div>
    </>
  )
}

export default EventCreateFormStep2