import React from 'react'
import SectionHeader from './SectionHeader'
import EventCreateChooseCoursesTable from './EventCreateChooseCoursesTable'

function EventCreateFormStep5({ courses, coursesStatus, selectedVenue, setSelectedVenue, selectedVenueName, setSelectedVenueName }) {
  return (
    <>
        <SectionHeader
            title={'Venue'}
            subtitle={'Please choose the venue for this event'}
            size={1}
            spaced={true}
            className="text-center mt-3 mb-3"
        />
        {coursesStatus === 'loading' && (
            <div>Loading Venues...</div>
        )}
        {courses && 
            <EventCreateChooseCoursesTable selectedVenueName={selectedVenueName} selectedVenue={selectedVenue} setSelectedVenue={setSelectedVenue} courses={courses} setSelectedVenueName={setSelectedVenueName}/>
        }
    </>
  )
}

export default EventCreateFormStep5