import React from "react";
import { Card } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

function EventChatPost({ item }) {
  return (
    <Col xs="12" className="">
      <Card>
        <Card.Header style={{ background: "#2D083F", color: "white" }}>
          <Row>
            <Col>{item.users_public.name}</Col>
            <Col className="text-right">
              <small>
                <small>
                  <small> ({new Date(item.created_at).toLocaleString()})</small>
                </small>
              </small>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>{item.post}</Card.Body>
      </Card>
    </Col>
  );
}

export default EventChatPost;
