import React from 'react'
import { useGroupScorecards } from '../util/db'
import EventManageCardsFullCard from './EventManageCardsFullCard'
import { Spinner } from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import { useState } from 'react'
import './EventManageCardsFull.css'; // Import the custom CSS file

function EventManageCardsFull({eventId,uid}) {
    const {
        data:cards,
        status:cardsStatus,
        error:cardsError
    } = useGroupScorecards(eventId)

    const [isShowAll, setIsShowAll] = useState(false);

    const handleToggleShowAll = () => {
      setIsShowAll(!isShowAll);
    };
    
    let allCardsEmpty = true

    //if there are items (hook is complete, no errors)loop through items
    if(cards !== null && cards !== undefined){
        cards.forEach((item, index) => {
            //if group_scorecard_players is not empty
            if(item.group_scorecard_players.length > 0){
                allCardsEmpty = false
            }
        })
    }

    return (
        <>
            <Row>
                <Col className='text-right'>
                    <Form.Check 
                        type="switch"
                        id="show-all-switch"
                        label="Show All"
                        checked={isShowAll}
                        onChange={handleToggleShowAll}
                        className="mb-3 large-switch"
                    />
                </Col>
            </Row>
            <Row>
                {cardsStatus === 'loading' && (
                    <Spinner animation="border" role="status" />
                )}
                {cardsStatus === 'error' && (
                    <div>Error: {cardsError.message}</div>
                )}
            </Row>
            
            {cardsStatus !== "loading" && cards && cards.length > 0 && (
                cards.map((card, index) => (
                    card.group_scorecard_players.length !== 0 && (  
                        <Row key={index}>
                            <Col >
                                <EventManageCardsFullCard uid={uid} card={card} key={index} eventId={eventId} isShowAll={isShowAll} />
                            </Col>
                        </Row>
                    )
                ))
            )}
            {cardsStatus !== "loading" && cards && cards.length === 0 && (
                <Container>
                    No cards yet
                </Container>
            )}
            {cardsStatus !== "loading" && cards && cards.length !== 0 && allCardsEmpty && (
                <Container>
                    No cards yet
                </Container>
            )}
        
        </>
    )
}

export default EventManageCardsFull