import React from 'react';
import ScorecardThrowRowTotalScoreBox from './ScorecardThrowRowTotalScoreBox';

function ScorecardThrowRowScoreboxTd({isUpdatingScore, isComplete, thisScore, hole_scores_id, dbResponseScore, onClickScore, onClickLockedScore}) {

  return (
    isComplete
        ?
        <>
          <td style={{textAlign:'center',width:'30px'}} onClick={()=>onClickLockedScore()}>
            <ScorecardThrowRowTotalScoreBox backgroundColor={'#ffffff'} textColor={'#2D083F'} text={thisScore} />
          </td>
        </>
        :      
          thisScore === null || thisScore === undefined //this means there was no score entered yet
            ? 
              hole_scores_id !== null //if hole_scores_id is not null, then the hole is present in the database. this always should be true. Can remove when promise issue is fixed. it might be fixed already, unsure.
              ? 
              <>
                <td style={{textAlign:'center'}} onClick={()=>onClickScore()}>
                  <ScorecardThrowRowTotalScoreBox backgroundColor={'#ffffff'} textColor={'#BFBFBF'} text={'?'} />
                </td>
              </>
              : //hole_scores_id is null. This is an error state
              <>
                <td style={{textAlign:'center'}} onClick={()=>onClickScore()}>
                  <ScorecardThrowRowTotalScoreBox backgroundColor={'red'} textColor={'#d9e3ff'} text={''} />
                </td>
              </>
            : //thisScore is not null or undefined. This means that there was already a score entered
              <>
                {dbResponseScore !== null ? //if dbResponseScore is not null, then the score was saved to the database
                  <>
                  <td style={{textAlign:'center'}} onClick={()=>onClickScore()}>
                    <ScorecardThrowRowTotalScoreBox backgroundColor={'#ffffff'} textColor={'#2D083F'} text={thisScore} />
                  </td>
                  </>
                : //black text if there is already a score in the db. and gray if there is not yet
                
                  <>
                  {isUpdatingScore
                   ?
                    <>
                    <td style={{textAlign:'center'}} onClick={()=>onClickScore()}>
                      <ScorecardThrowRowTotalScoreBox backgroundColor={'#ffffff'} textColor={'#c0b4c5'} text={thisScore} />
                    </td>
                    </>
                   :
                    <>
                    
                    {/* casting thisscore to string to prevent warning */}
                    <td style={{textAlign:'center'}} onClick={()=>onClickScore()}>
                      <ScorecardThrowRowTotalScoreBox backgroundColor={'#ffffff'} textColor={'#2D083F'} text={thisScore.toString()} />
                    </td>
                    </>
                   }
                  </>
                }
              </>     
  );
}

export default ScorecardThrowRowScoreboxTd;