import React from "react";
import SectionHeader from "./SectionHeader";
import EventCreateChooseLayoutsTable from "./EventCreateChooseLayoutsTable";

function EventCreateFormStep6({
  layouts,
  layoutsStatus,
  selectedDivisions,
  setSelectedDivisions,
}) {
  return (
    <>
      <SectionHeader
        title={"Layouts"}
        subtitle={"Please choose the layouts for this event"}
        size={1}
        spaced={true}
        className="text-center mt-3 mb-3"
      />
      {layoutsStatus === "loading" && <div>Loading Layouts...</div>}
      {layouts && selectedDivisions && (
        <EventCreateChooseLayoutsTable
          selectedDivisions={selectedDivisions}
          setSelectedDivisions={setSelectedDivisions}
          layouts={layouts}
        />
      )}
    </>
  );
}

export default EventCreateFormStep6;
