import React from 'react';
import ScorecardThrowScoreBox from './ScorecardThrowScoreBox';
import { updateThrowScore } from '../util/db';

function ScorecardThrowRowScoreBox({ throwScore, throwScoresId, setThrowScore, gameId, isComplete }) {
    let thisThrowScore = '?';
    let backgroundColor = '#ffffff';
    let textColor = '#BFBFBF';

    if (throwScore !== null && throwScore !== undefined) {
        thisThrowScore = String(throwScore);
    }

    function onClickThrowScore() {

        //if event is complete, do not allow score to be updated
        if (!isComplete) {

            let newThrowScore = 0

            if(throwScore === null || throwScore === undefined ) {
                newThrowScore = 1
            }else if (throwScore === 1) {
                newThrowScore = 2
            }else if (throwScore === 2) {
                newThrowScore = 0
            }else if (throwScore === 0) {
                newThrowScore = null
            }

            setThrowScore(newThrowScore)

            updateThrowScore(throwScoresId, newThrowScore).then((res) => {
                // console.log('Throw score updated:')
            }).catch((err) => {
                console.error('Error updating throw score:', err)
            })
        }else{
            alert('This scorecard has already been submitted. If you need to make edits, please talk to the event director.')
        }
    }

    return (
        <td style={{ textAlign: 'center', width: '30px', outline: 'none' }} onClick={onClickThrowScore}>
          <ScorecardThrowScoreBox gameId={gameId} backgroundColor={backgroundColor} textColor={textColor} thisThrowScore={thisThrowScore} />
        </td>
      );
}

export default ScorecardThrowRowScoreBox;