import React from "react";
import { Row, Col, Spinner } from "react-bootstrap";
import ScorecardShowScorecardCard from "./ScorecardShowScorecardCard";
import ScorecardCreateScorecardCard from "./ScorecardCreateScorecardCard";

function ScorecardBeginScorecardExists({ items, itemsStatus, itemsAreEmpty, eventId, auth, gameId }) {

    return (
        <Row>
            <Col>
                {(itemsStatus === "loading" || itemsAreEmpty) && (//loading or no items
                    <div className="py-5 px-3 align-self-center">
                        {itemsStatus === "loading" && (
                            <Spinner animation="border" variant="primary">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        )}

                        {itemsStatus !== "loading" && itemsAreEmpty && (
                            <>
                            ...
                            </>
                        )}
                    </div>
                )}
                                        
                {itemsStatus !== "loading" && items && items.length > 0 && ( //entries exist
                    items.map((item, index) => (
                        <React.Fragment key={index}>
                            {item.group_scorecard_players && item.group_scorecard_players.length > 0
                            ? //player is on a scorecard
                                <ScorecardShowScorecardCard item={item} index={index} gameId={gameId} />
                            : //player is not on a scorecard
                                <ScorecardCreateScorecardCard item={item} index={index} eventId={eventId} auth={auth} gameId={gameId} />
                            }
                        </React.Fragment>
                    ))
                )}    
            </Col>
        </Row>
    );
}

export default ScorecardBeginScorecardExists;