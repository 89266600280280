import React from 'react'

function EventManageCardsFullCardActiveOrVerified({isHandled,card}) {
    if (isHandled) {
        return null;
      }
    
      return (
        !card.is_complete ? (
          <div className='mb-2 text-muted' style={{ background: "#ffffff", height: "30px", borderRadius: "10%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            🥏 Active
          </div>
        ) : (
          <div className='mb-2 text-muted' style={{ background: "#DFF0D8", height: "30px", borderRadius: "10%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            ✔️ Verified
          </div>
        )
    );
}

export default EventManageCardsFullCardActiveOrVerified