import React, { useEffect } from 'react'
import { useState } from 'react';
import { insertScoreEdit, updateHoleScore, updateThrowScore } from '../util/db';
import { useAuth } from '../util/auth.js';
import { useRouter } from '../util/router';
import ScorecardRowPlayerTd from './ScorecardRowPlayerTd.js';
import ScorecardRowHoleinfoToast from './ScorecardRowHoleinfoToast.js';
import ScorecardThrowsRowScoreboxTd from './ScorecardThrowsRowScoreboxTd.js';
import ScorecardThrowsRowScoreBox from './ScorecardThrowsRowScoreBox.js';

function ScorecardThrowsRow({player, currentHole, isComplete, showTotals}) {

  const currentHoleHoleScore = player.event_entries.hole_scores.filter((hole_score) => hole_score.layout_holes.hole_order === currentHole)[0]
  const router = useRouter()
  const auth = useAuth();
  const [showHoleInfo, setShowHoleInfo] = useState(false);
  const [dbResponseScore, setDbResponseScore] = useState(null)
  const [thisScore, setThisScore] = useState()
  // const [isBonusPoint,setIsBonusPoint] = useState()
  // let isBonusPoint = false
  
  const [throwOneScore, setThrowOneScore] = useState(currentHoleHoleScore.throw_scores[0].throw_score)
  const [throwTwoScore, setThrowTwoScore] = useState(currentHoleHoleScore.throw_scores[1].throw_score)
  const [throwOneThrowScoresId, setThrowOneThrowScoresId] = useState(currentHoleHoleScore.throw_scores[0].id)
  const [throwTwoThrowScoresId, setThrowTwoThrowScoresId] = useState(currentHoleHoleScore.throw_scores[1].id)
  const [isUpdatingScore] = useState(false);
  // const [isCreating, setIsCreating] = useState(false);
  const toggleHoleInfo = () => setShowHoleInfo(!showHoleInfo);

  const event_entries_id = player.event_entries_id
  const hole_scores_id=getHolescoreId()
  const uid = auth.user ? auth.user.uid : undefined;
  
  const gameId = player.event_entries.events.game_id

  //calculate this players total score
  let total = 0
  player.event_entries.hole_scores.forEach((score, index) => {
      total += score.score  
  }) 

  function onViewEdits() {
    //redirect to /holescoreedits
    router.push(`/holescoreedits?hole=${hole_scores_id}&event=${player.event_entries.event_id}`)
  }

  //function to log hole_score_edit into database
  function logHoleScoreEdit(hole_score_id,newScore,uid) {
    insertScoreEdit({hole_scores_id:hole_score_id,score:newScore,editor_id: uid})
  }

  function onClickLockedScore() {
    alert('This scorecard is locked. Please contact the event organizer to make changes.')
  }

  function getHolescoreId() {
    let hole_scores_id = null
    player.event_entries.hole_scores.map((hole_score, index) => {
      if (hole_score.layout_holes.hole_order === currentHole) {
        hole_scores_id = hole_score.hole_scores_id
      }
      return hole_scores_id
    })  
    return hole_scores_id
  }

  function onClickScore() {
    
    // if currentHoleHoleScore.score is null, set holescore to 5 and throw scores to 2
    // if currentHoleHoleScore.score is 5, set holeScore to 0 and throw scores to 0
    // else if curcurrentHoleHoleScore.score is 0 set holeScore to null and throw scores to null

    let newHoleScore = null
    let newThrowOneScore = null
    let newThrowTwoScore = null

    if(currentHoleHoleScore.score === null){
      newHoleScore = 5
      newThrowOneScore = 2
      newThrowTwoScore = 2

      // update throw score object
      currentHoleHoleScore.throw_scores[0].throw_score = newThrowOneScore
      currentHoleHoleScore.throw_scores[1].throw_score = newThrowTwoScore

      //update hole score in currentHoleHoleScore
      currentHoleHoleScore.score = newHoleScore

      //update throw scores in database
      updateThrowScore(throwOneThrowScoresId, newThrowOneScore).then((res) => {
        // console.log('Throw score updated:')
      }).catch((err) => {
          console.error('Error updating throw score:', err)
      })

      updateThrowScore(throwTwoThrowScoresId, newThrowTwoScore).then((res) => {
        // console.log('Throw score updated:')
      }).catch((err) => {
          console.error('Error updating throw score:', err)
      })

      //update hole score in database
      updateHoleScore(hole_scores_id, event_entries_id, { score: newHoleScore }).then((res) => {
        setDbResponseScore(res[0].score);
      });


      //update throw scores in state
      setThrowOneScore(newThrowOneScore)
      setThrowTwoScore(newThrowTwoScore)
      setThisScore(newHoleScore)

    }else if(currentHoleHoleScore.score === 5){
      newHoleScore = 0
      newThrowOneScore = 0
      newThrowTwoScore = 0
      // update throw score object
      currentHoleHoleScore.throw_scores[0].throw_score = newThrowOneScore
      currentHoleHoleScore.throw_scores[1].throw_score = newThrowTwoScore

      //update hole score in currentHoleHoleScore
      currentHoleHoleScore.score = newHoleScore

      //update throw scores in database
      updateThrowScore(throwOneThrowScoresId, newThrowOneScore).then((res) => {
        // console.log('Throw score updated:')
      }).catch((err) => {
          console.error('Error updating throw score:', err)
      })

      updateThrowScore(throwTwoThrowScoresId, newThrowTwoScore).then((res) => {
        // console.log('Throw score updated:')
      }).catch((err) => {
          console.error('Error updating throw score:', err)
      })

      //update hole score in database
      updateHoleScore(hole_scores_id, event_entries_id, { score: newHoleScore }).then((res) => {
        setDbResponseScore(res[0].score);
      });


      //update throw scores in state
      setThrowOneScore(newThrowOneScore)
      setThrowTwoScore(newThrowTwoScore)
      setThisScore(newHoleScore)
    }else if(currentHoleHoleScore.score === 0){
      newHoleScore = null
      newThrowOneScore = null
      newThrowTwoScore = null

      // update throw score object
      currentHoleHoleScore.throw_scores[0].throw_score = newThrowOneScore
      currentHoleHoleScore.throw_scores[1].throw_score = newThrowTwoScore

      //update hole score in currentHoleHoleScore
      currentHoleHoleScore.score = newHoleScore

      //update throw scores in database
      updateThrowScore(throwOneThrowScoresId, newThrowOneScore).then((res) => {
        // console.log('Throw score updated:')
      }).catch((err) => {
          console.error('Error updating throw score:', err)
      })

      updateThrowScore(throwTwoThrowScoresId, newThrowTwoScore).then((res) => {
        // console.log('Throw score updated:')
      }).catch((err) => {
          console.error('Error updating throw score:', err)
      })

      //update hole score in database
      updateHoleScore(hole_scores_id, event_entries_id, { score: newHoleScore }).then((res) => {
        setDbResponseScore(res[0].score);
      });


      //update throw scores in state
      setThrowOneScore(newThrowOneScore)
      setThrowTwoScore(newThrowTwoScore)
      setThisScore(newHoleScore)
    }
  }

  // function holeScoresIdIsNull() {
  //   return hole_scores_id === null;
  // }
  
   //useEffect to set thisScore to the current hole score
  useEffect(() => {

    function loadHoleScore() {
      const this_hole_score = player.event_entries.hole_scores.filter(
        (hole_score) => hole_score.layout_holes.hole_order === currentHole
      )
      //if this_hole_score[0] is not undefined, return it
      if(this_hole_score[0] !== undefined){
      // return this_hole_score[0].score
      setThisScore(this_hole_score[0].score)
      }
    }

    function loadThrowOneScore() {
      const this_hole_score = player.event_entries.hole_scores.filter(
        (hole_score) => hole_score.layout_holes.hole_order === currentHole
      )
      //if this_hole_score[0] is not undefined, return it
      if(this_hole_score[0] !== undefined){
      // return this_hole_score[0].throw_scores[0].throw_score
      setThrowOneScore(this_hole_score[0].throw_scores[0].throw_score)
      setThrowOneThrowScoresId(this_hole_score[0].throw_scores[0].id)
      }
    }

    function loadThrowTwoScore() {
      const this_hole_score = player.event_entries.hole_scores.filter(
        (hole_score) => hole_score.layout_holes.hole_order === currentHole
      )
      
      //if this_hole_score[0] is not undefined, return it
      if(this_hole_score[0] !== undefined){
        // return this_hole_score[0].throw_scores[1].throw_score
        setThrowTwoScore(this_hole_score[0].throw_scores[1].throw_score)
        setThrowTwoThrowScoresId(this_hole_score[0].throw_scores[1].id)
      }
    }
    
    //if player.event_entries.holes_scores is not empty, load scores into state
    if(player.event_entries.hole_scores.length !== 0){
      // setThisScore(loadHoleScore())  
      loadHoleScore()
    }else{
      console.log('error, no hole score for ', currentHole)
    }

    loadThrowOneScore()
    loadThrowTwoScore()

    //reset dbResponseScore to null
    setDbResponseScore(null)

    // eslint-disable-next-line
  }, [currentHole])

  function handleThrowScoreChange(throwScoresId, newThrowScore, throwNumber){

    // update throw score object
    currentHoleHoleScore.throw_scores[throwNumber-1].throw_score = newThrowScore

    //update throw score in database
    updateThrowScore(throwScoresId, newThrowScore).then((res) => {
      // console.log('Throw score updated:')
    }).catch((err) => {
        console.error('Error updating throw score:', err)
    })

    //update throw score state
    if(throwNumber === 1){
      setThrowOneScore(newThrowScore)
    }else if(throwNumber === 2){
      setThrowTwoScore(newThrowScore)
    }

    let newHoleScore = calculateHoleScore()

    ////update hole score in currentHoleHoleScore
    currentHoleHoleScore.score = newHoleScore

    //update the hole score in the database
    updateHoleScore(hole_scores_id, event_entries_id, { score: newHoleScore }).then((res) => {
      setDbResponseScore(res[0].score);
    });

    // update hole score in state
    setThisScore(newHoleScore);

    logHoleScoreEdit(hole_scores_id, newHoleScore, uid);
  }

  function calculateHoleScore() {
    let throwOneScore_ = currentHoleHoleScore.throw_scores[0].throw_score
    let throwTwoScore_ = currentHoleHoleScore.throw_scores[1].throw_score
    let newHoleScore = (throwOneScore_ !== null ? Number(throwOneScore_) : 0) + (throwTwoScore_ !== null ? Number(throwTwoScore_) : 0);

    // if newscore === 4, set it to 5
    if(newHoleScore === 4){
      newHoleScore = 5
    }

    return newHoleScore
  }

  return (
    <>
      <tr>
        <ScorecardRowPlayerTd playerName={player.event_entries.users_public.name} showTotals={showTotals} total={total} toggleHoleInfo={toggleHoleInfo} />
        <ScorecardThrowsRowScoreBox throwId={1} isComplete={isComplete} gameId={gameId} currentHoleHoleScore={currentHoleHoleScore} throwScore={throwOneScore} handleThrowScoreChange={handleThrowScoreChange} throwScoresId={throwOneThrowScoresId}/>
        <ScorecardThrowsRowScoreBox throwId={2} isComplete={isComplete} gameId={gameId} currentHoleHoleScore={currentHoleHoleScore} throwScore={throwTwoScore} handleThrowScoreChange={handleThrowScoreChange} throwScoresId={throwTwoThrowScoresId} />
        <ScorecardThrowsRowScoreboxTd isUpdatingScore={isUpdatingScore} isComplete={isComplete} thisScore={thisScore} hole_scores_id={hole_scores_id} dbResponseScore={dbResponseScore}
          onClickScore={onClickScore} 
          onClickLockedScore={onClickLockedScore} 
        />
      </tr>
      <tr>
        <ScorecardRowHoleinfoToast player={player} currentHole={currentHole} showHoleInfo={showHoleInfo} toggleHoleInfo={toggleHoleInfo} onViewEdits={onViewEdits} />
      </tr>
    </>
  )
}

export default ScorecardThrowsRow