import React from 'react'

import { Table } from 'react-bootstrap'


function EventManageCardsFullCardHeaderTable({card, isCardComplete}) {
    return (
        <Table size='sm' className='px-0 py-0'>
            <tbody className='px-0 py-0' style={{background: isCardComplete ? '#DFF0D8' : '#FFFFFF'}}>
                <tr>
                    <td>
                        Created:
                    </td>
                    <td>
                        {new Date(card.created_at).toLocaleTimeString()}
                    </td>
                </tr>
                <tr>
                    <td>
                        Finished: 
                    </td>
                    <td>
                        {card.completed_at ? new Date(card.completed_at).toLocaleTimeString() : 'In Progress'}
                    </td>   
                </tr>
                {card.completed_at && (
                    <tr>
                        <td>
                            Time to Complete:
                        </td>
                        <td>
                            {Math.floor((new Date(card.completed_at) - new Date(card.created_at))/60000)} minutes
                        </td>
                    </tr>   
                )}
            </tbody>
        </Table>
  )
}

export default EventManageCardsFullCardHeaderTable