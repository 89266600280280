import React, {useState} from 'react'
import { ListGroup } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
// import { useAuth } from '../util/auth';
import { createGroupScorecardPlayers } from '../util/db';
// import { createHoleScore } from '../util/db'; 
// import { getLayoutHoles } from '../util/db';
import { useRouter } from '../util/router';
import ScorecardPlayerSelectRow from './ScorecardPlayerSelectRow';

function ScorecardAddPlayerSelect (props){

  const [selectedPlayers, setSelectedPlayers] = useState([])
  const [confirmDisabled, setConfirmDisabled] = useState(false)
  const router = useRouter();

  const handleConfirmPlayers = () => {

    if(selectedPlayers.length !== 0){
      setConfirmDisabled(true)

        // index should start at selectedPlayers.length
        let index=(selectedPlayers.length + 1)

        //loop through selected players, and add to group_scorecard_players table
        selectedPlayers.forEach((player) => {
          const dataGroupScorecardPlayers = {
              group_scorecard_id: props.scorecardId,
              event_entries_id: player,
              order: index
          }

          
          //create group scorecard players
          createGroupScorecardPlayers(dataGroupScorecardPlayers)

          // //redirect to scorecard
          router.push(`/scorecard?scorecard=${props.scorecardId}`)
        })
    //   })
    }else{
      alert('Please select players to create a scorecard.')
    }
  }

  const availablePlayers = props.items

  return(        
    <div>
      <ListGroup as="ul">
        {availablePlayers.map((item,index) => (   
            <ScorecardPlayerSelectRow key={index} item={item} selectedPlayers={selectedPlayers} setSelectedPlayers={setSelectedPlayers}/>
        ))}
      </ListGroup>
      <br />
      <div className='text-center'>
        <Button disabled={confirmDisabled} className='w-100' onClick={handleConfirmPlayers}>
          Add to Scorecard
        </Button>
      </div>
    </div>
  )
}

export default ScorecardAddPlayerSelect