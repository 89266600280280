import React from "react";
import { usePastEvents } from "../util/db";
import FormAlert from "./FormAlert";
import EventsTablePast from "./EventsTablePast";

function EventsPagePast() {
  const {
    data: items,
    status: itemsStatus,
    error: itemsError,
  } = usePastEvents();

  return (
    <>
      {itemsError && (
        <div className="mb-3">
          <FormAlert type="error" message={itemsError.message} />
        </div>
      )}

      {itemsStatus !== "loading" && items && items.length > 0 && (
        <>
          <EventsTablePast events={items} />
        </>
      )}
    </>
  );
}

export default EventsPagePast;
