import { Container, ListGroup } from "react-bootstrap";
import { requireAuth } from "./../util/auth";
import { useGroupScorecardPlayers } from "./../util/db";
import { useRouter } from "./../util/router";
import SectionHeader from "./../components/SectionHeader";
import { useEffect, useState } from "react";
import ScorecardOrderListItem from "../components/ScorecardOrderListItem";
import { updateOrderGroupScorecardPlayer } from "./../util/db";
import './scorecard_order.css'; // Import custom CSS
import ScorecardBackToScorecardButton from "../components/ScorecardBackToScorecardButton";

function ScorecardOrderPage() {
    const router = useRouter();
    //get players from scorecard
    const {
        data: items,
        // status: itemsStatus,
        // error: itemsError,
    } = useGroupScorecardPlayers(router.query.scorecard);

    const [sortedItems, setSortedItems] = useState([]);
    const [isUpdating, setIsUpdating] = useState(false);

    useEffect(() => {
        // console.log('items changed:', items);
        if (items) {
            const sorted = [...items].sort((a, b) => a.order - b.order);
            setSortedItems(sorted);
        }
    }, [items]);


    const moveItemUp = async (index, event) => {
        if (index === 0 || isUpdating) return; // Can't move the first item up or if updating

        setIsUpdating(true);

        const itemToMoveUpId = sortedItems[index].id;
        const itemToMoveDownId = sortedItems[index - 1].id;
        const itemToMoveUpStartingOrder = sortedItems[index].order;
        const itemtoMoveUpEndingOrder = itemToMoveUpStartingOrder - 1;
        const itemToMoveDownStartingOrder = sortedItems[index - 1].order;
        const itemToMoveDownEndingOrder = itemToMoveDownStartingOrder + 1;

        try {
            // Update order in db. move item up
            await updateOrderGroupScorecardPlayer(itemToMoveUpId, itemtoMoveUpEndingOrder);

            // Update order in db. move item down
            await updateOrderGroupScorecardPlayer(itemToMoveDownId, itemToMoveDownEndingOrder);

            //reload page
            window.location.reload();

        } catch (error) {
            console.error('Error updating order:', error);
        } finally {
            setIsUpdating(false);
        }
    };

    const moveItemDown = async (index, event) => {
        if (index === sortedItems.length - 1 || isUpdating) return; // Can't move the last item down or if updating

        setIsUpdating(true);

        //match logic from up but reverse
        const itemToMoveDownId = sortedItems[index].id;
        const itemToMoveUpId = sortedItems[index + 1].id;
        const itemToMoveDownStartingOrder = sortedItems[index].order;
        const itemToMoveDownEndingOrder = itemToMoveDownStartingOrder + 1;
        const itemToMoveUpStartingOrder = sortedItems[index + 1].order;
        const itemtoMoveUpEndingOrder = itemToMoveUpStartingOrder - 1;

        try {
            // Update order in db. move item down
            await updateOrderGroupScorecardPlayer(itemToMoveDownId, itemToMoveDownEndingOrder);

            // Update order in db. move item up
            await updateOrderGroupScorecardPlayer(itemToMoveUpId, itemtoMoveUpEndingOrder);

            //reload page
            window.location.reload();
        } catch (error) {
            console.error('Error updating order:', error);
        }
        finally {
            setIsUpdating(false);
        }
    };

    return (
        <Container>
            <SectionHeader
                size={1}
                spaced={true}
                className="text-center mt-4"
                title={"Scorecard Order"}
            />
            <ListGroup>
                {sortedItems?.map((item, index) => (
                    <ScorecardOrderListItem
                        key={item.id}
                        item={item}
                        index={index}
                        moveItemUp={moveItemUp}
                        moveItemDown={moveItemDown}
                        isUpdating={isUpdating}
                        sortedItems={sortedItems}
                    />
                ))}
            </ListGroup>
            <ScorecardBackToScorecardButton router={router} />
        </Container>
    );
}

export default requireAuth(ScorecardOrderPage);