import React from 'react'
import { Card, Spinner } from 'react-bootstrap'
import EventManageCardsFullCardActiveOrVerified from './EventManageCardsFullCardActiveOrVerified'


function EventManageCardsFullCardHeader({card, isHandled, isHandling, isShowAll, onHandleCard, onUnhandleCard}) {
    return (
        <Card.Header style={{backgroundColor:'#2d083F',color:"#FFFFFF"}} className='pb-0'>
            <Card.Title style={{fontWeight:'bold'}}>
                Card {card.id}
            </Card.Title>
            <Card.Subtitle className='mb-2 text-muted'>
                {card.group_scorecard_players.length} Players
            </Card.Subtitle>
            <EventManageCardsFullCardActiveOrVerified isHandled={isHandled} card={card} />
            {!isShowAll && (
                <div style={{
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                    background: '#FFFFFF',
                    borderRadius: '10%',
                    height: '30px',
                    width: '30px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    {isHandling 
                            ? 
                                <Spinner animation="border" variant='primary' />
                            :
                                isHandled 
                                    ? 
                                        <div style={{cursor:'pointer'}} onClick={onUnhandleCard}>📗</div> 
                                    : 
                                        <div style={{cursor:'pointer'}} onClick={onHandleCard}>📖</div>
                    }
                </div>
            )}
        </Card.Header>
    )
}

export default EventManageCardsFullCardHeader