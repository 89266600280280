import React from "react";
import Table from "react-bootstrap/Table";
import EventCreateDisplayFeesRow from "./EventCreateDisplayFeesRow";

function EventCreateDisplayFeesTable({
  selectedDivisions,
  layouts,
  payoutMethods,
}) {
  return (
    <Table responsive className="table table-striped">
      <thead style={{ background: "#2D083F" }}>
        <tr>
          <th className="text-center">
            <p style={{ color: "#ffffff" }}>Division</p>
          </th>

          <th className="text-center">
            <p style={{ color: "#ffffff" }}>Total Charge</p>
          </th>
          <th className="text-center">
            <p style={{ color: "#ffffff" }}>Layout</p>
          </th>
          <th className="text-center">
            <p style={{ color: "#ffffff" }}>Event Payout</p>
          </th>
          {/* <th className='text-center'>
                            <p style={{color:'#ffffff'}}>
                                Series Payout
                            </p>
                    </th> */}
          <th className="text-center">
            <p style={{ color: "#ffffff" }}>Event Costs</p>
          </th>
          {/* <th className="text-center">
            <p style={{ color: "#ffffff" }}>Event Payout Method</p>
          </th> */}
          {/* <th className="text-center">
            <p style={{ color: "#ffffff" }}>Series Payout Method</p>
          </th> */}
        </tr>
      </thead>
      <tbody>
        {selectedDivisions.map((selectedDivision, index) => (
          // <EventCreateDisplayFeesRow key={index} selectedDivision={selectedDivision} selectedDivisions={selectedDivisions} setSelectedDivisions={setSelectedDivisions} />
          <EventCreateDisplayFeesRow
            layouts={layouts}
            payoutMethods={payoutMethods}
            key={index}
            selectedDivision={selectedDivision}
          />
        ))}
      </tbody>
    </Table>
  );
}

export default EventCreateDisplayFeesTable;
