import React from 'react';
import ScorecardThrowsRowScoreBoxRow from './ScorecardThrowsRowScoreBoxRow';
function ScorecardThrowsRowScoreBox({ throwScore, throwScoresId, handleThrowScoreChange, gameId, isComplete, throwId }) {

    return (
        <td style={{ 
                textAlign: 'center',
                width: '30px', 
                outline: 'none',
                padding: '0px',
                verticalAlign: 'middle',
                paddingRight: throwId === 1 ? '10px' : '0px',
                paddingLeft: throwId === 2 ? '10px' : '0px',
        }}>
          <ScorecardThrowsRowScoreBoxRow 
            gameId={gameId} 
            throwScore={throwScore} 
            throwScoresId={throwScoresId} 
            handleThrowScoreChange={handleThrowScoreChange} 
            isComplete={isComplete}
            throwId={throwId}
             />
        </td>
    );
}

export default ScorecardThrowsRowScoreBox;