import React, { useState,useEffect } from 'react'
import { Card } from 'react-bootstrap'
import { updateScorecardIsSubmitted } from '../util/db'
import { updateScorecardNotSubmitted } from '../util/db'
import { updateScorecardIsHandled } from '../util/db'
import { updateScorecardIsNotHandled } from '../util/db'
import EventManageCardsFullCardHeaderTable from './EventManageCardsFullCardHeaderTable'
import EventManageCardsFullCardFooter from './EventManageCardsFullCardFooter'
import EventManageCardsFullCardHeader from './EventManageCardsFullCardHeader'
import EventManageCardsFullCardsTable from './EventManageCardsFullCardsTable'

function EventManageCardsFullCard({card,uid, eventId, isShowAll}) {
    const [isCardComplete,setIsCardComplete] = useState(card.is_complete)
    const [isHandled,setIsHandled] = useState(card.is_handled)
    const [isHandling,setIsHandling] = useState(false)
    

    //useeffect to refresh component when card is complete
    useEffect(() => {
        setIsCardComplete(card.is_complete)
    }, [card.is_complete])
    
    function onLockCard() {
        updateScorecardIsSubmitted(card.id, card.event_id, uid)
        //set card iscomplete to true
        setIsCardComplete(true)
    }

    function onUnlockCard() {
        updateScorecardNotSubmitted(card.id, card.event_id, uid)
        //set card iscomplete to false
        setIsCardComplete(false)
    }

    function onHandleCard() {

        // if card is not complete, do not allow to be handled
        if (!isCardComplete) {
            alert('Please lock the card before collapsing')
        } else {
            setIsHandling(true)
            setIsHandled(true)

            //update in db
            updateScorecardIsHandled(card.id, card.event_id).then(() => {
                setIsHandling(false)
            }).catch((error) => {
                console.error('Error updating scorecard is_handled', error)
                setIsHandled(false)
                setIsHandling(false)
            })
        }
    }

    function onUnhandleCard() {
        setIsHandling(true)
        setIsHandled(false)

        //update in db
        updateScorecardIsNotHandled(card.id, card.event_id).then(() => {
            setIsHandling(false)
        }).catch((error) => {
            console.error('Error updating scorecard is_handled', error)
            setIsHandled(true)
            setIsHandling(false)
        })
    }

    return (
        <Card className='m-1 mb-5' style={{background:"#2d083F"}}>
            <EventManageCardsFullCardHeader card={card} isHandled={isHandled} isHandling={isHandling} isShowAll={isShowAll} onHandleCard={onHandleCard} onUnhandleCard={onUnhandleCard} />
            <Card.Body className='text-center px-0 py-0'>
                {(!isHandled || isShowAll) && (
                    <EventManageCardsFullCardHeaderTable card={card} isCardComplete={isCardComplete} />
                )}
                {(!isHandled || isShowAll) && (
                    <EventManageCardsFullCardsTable card={card} isCardComplete={isCardComplete} eventId={eventId} />
                )}
            </Card.Body>
            {!isHandled && (
                <EventManageCardsFullCardFooter isCardComplete={isCardComplete} onLockCard={onLockCard} onUnlockCard={onUnlockCard} card={card} />
            )}
        </Card>
    )
}

export default EventManageCardsFullCard