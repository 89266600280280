import React from "react";
import { useForumPostsNotHidden } from "../util/db";
import EventChatPost from "./EventChatPost";
import Row from "react-bootstrap/Row";

function EventChatDisplayPosts({ posts }) {
  return (
    <div className="mb-3">
      {posts &&
        posts.length > 0 &&
        posts.map((post, index) => (
          <Row className="mt-3" key={index}>
            <EventChatPost key={index} item={post} />
          </Row>
        ))}
    </div>
  );
}

export default EventChatDisplayPosts;
