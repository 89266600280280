import React, { useEffect, useState } from "react";
import Meta from "./../components/Meta";
import { useRouter } from "../util/router";
import EventSection from "../components/EventSection";
import EventSideGameSection from "../components/EventSideGameSection";
import { useAuth } from "../util/auth";
import { useEventName, useEventTdId } from "../util/db";
import EventTdSection from "../components/EventTdSection";
import CtaSection from "../components/CtaSection";
import DisplayLeaderboard from "../components/DisplayLeaderboard";
import SectionHeader from "../components/SectionHeader";
import HrCustom from "../components/HrCustom";
import { Container } from "react-bootstrap";
import EventChat from "../components/EventChat";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import EventScorecards from "../components/EventScorecards";
import { useEventEntriesByEventUser } from "../util/db";
import { useGroupScorecardsByEventUser } from "../util/db";
import { useForumPostsNotHidden } from "../util/db";

function EventPage(props) {
  const router = useRouter();
  const auth = useAuth();
  const eventId = router.query.event;
  const uid = auth.user ? auth.user.uid : "";
  const [isEventComplete, setIsEventComplete] = useState(false);
  const [eventName, setEventName] = useState("");
  const [eventDate, setEventDate] = useState();
  const [eventCourse, setEventCourse] = useState("");
  const [eventGameName, setEventGameName] = useState("");
  const [scoringOrderGolf, setScoringOrderGolf] = useState(null);
  const [tdId, setTdId] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [canCheckIn, setCanCheckIn] = useState(true);
  const [isIncompleteCards, setIsIncompleteCards] = useState(false);
  const [isCheckedInAlready, setIsCheckedInAlready] = useState(false);
  const [defaultActiveKey] = useState("scorecards");
  const [gameId, setGameId] = useState();

  //get posts from db hook
  const {
    data: posts,
    // status: itemsStatus,
    // error: itemsError,
  } = useForumPostsNotHidden(eventId);

  const {
    data: entries,
    // status: entriesStatus,
    // error: entriesError
  } = useEventEntriesByEventUser(eventId, uid);

  const {
    data: scorecards,
    status: scorecardsStatus,
    // error: scorecardsError
  } = useGroupScorecardsByEventUser(eventId, uid);

  useEffect(() => {
    if (scorecardsStatus !== "loading" && scorecards) {
      scorecards.forEach((scorecard) => {
        if (scorecard.group_scorecard.is_complete === false) {
          setIsIncompleteCards(true);
          setCanCheckIn(false);
        }
      });
    }

    if (entries && scorecards && entries.length !== scorecards.length) {
      setIsCheckedInAlready(true);
      setCanCheckIn(false);
    }
  }, [
    scorecards,
    entries,
    isIncompleteCards,
    isCheckedInAlready,
    canCheckIn,
    scorecardsStatus,
  ]);

  useEffect(() => {
    if (uid !== "") {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [uid]);

  const {
    data: items,
    status: itemsStatus,
    // error: itemsError,
  } = useEventName(eventId);

  useEffect(() => {
    itemsStatus !== "loading" &&
      items &&
      items.length > 0 &&
      setEventName(items[0].name);
  }, [items, itemsStatus]);

  useEffect(() => {
    if (itemsStatus !== "loading" && items && items.length > 0) {
      const eventDate = new Date(items[0].date);
      const utcDate = new Date(
        eventDate.getUTCFullYear(),
        eventDate.getUTCMonth(),
        eventDate.getUTCDate()
      );
      setEventDate(utcDate.toLocaleDateString());
    }
  }, [items, itemsStatus]);

  useEffect(() => {
    itemsStatus !== "loading" &&
      items &&
      items.length > 0 &&
      setEventCourse(items[0].courses.course_name);
  }, [items, itemsStatus]);

  useEffect(() => {
    itemsStatus !== "loading" &&
      items &&
      items.length > 0 &&
      setScoringOrderGolf(items[0].scoring_order_golf);
  }, [items, itemsStatus]);

  useEffect(() => {
    itemsStatus !== "loading" &&
      items &&
      items.length > 0 &&
      setEventGameName(items[0].games.name);
  }, [items, itemsStatus]);

  useEffect(() => {
    if (itemsStatus !== "loading" && items && items.length > 0) {
      setIsEventComplete(items[0].is_event_complete);
    }
  }, [items, itemsStatus]);

  useEffect(() => {
    // game_id
    if (itemsStatus !== "loading" && items && items.length > 0) {
      setGameId(items[0].game_id);
    }
  }, [items, itemsStatus]);

  const {
    data: itemsTd,
    status: itemsStatusTd,
    // error: itemsErrorTd,
  } = useEventTdId(eventId);

  useEffect(() => {
    itemsStatusTd !== "loading" &&
      itemsTd &&
      itemsTd.length > 0 &&
      setTdId(itemsTd[0].td_id);
  }, [itemsTd, itemsStatusTd]);

  let buttonPath = "/auth/signin?next=/event?event=" + eventId;

  //if posts.length>0, set chatTitle to Chat (posts.length). Otherwise, set chatTitle to Chat
  let chatTitle =
    posts && posts.length > 0 ? "Chat (" + posts.length + ")" : "Chat";

  return (
    <>
      <Meta title={eventName} />
      {!isLoggedIn && (
        <>
          <CtaSection
            bg="primary"
            textColor="light"
            size="sm"
            bgImage=""
            bgImageOpacity={1}
            title="Ready to play?"
            subtitle=""
            buttonText="Log In"
            // buttonColor="#4D81F4"
            buttonPath={buttonPath}
          />
          <HrCustom width={"100%"} />
        </>
      )}
      <Container>
        {isLoggedIn && tdId === uid && <EventTdSection eventId={eventId} />}
      </Container>

      <SectionHeader
        title={eventName}
        subsubsubtitle={eventGameName}
        subsubtitle={eventDate}
        subtitle={eventCourse}
        size={1}
        className="text-center mb-0"
      />
      <Container style={{ fontWeight: "bold" }}>
        <Tabs
          defaultActiveKey={defaultActiveKey}
          className="mb-3 mt-3"
          fill
          variant="pills"
        >
          <Tab eventKey="scorecards" title="Score">
            <EventScorecards
              isIncompleteCards={isIncompleteCards}
              isCheckedInAlready={isCheckedInAlready}
              canCheckIn={canCheckIn}
              eventId={eventId}
              uid={uid}
              isLoggedIn={isLoggedIn}
              isEventComplete={isEventComplete}
              auth={auth}
              gameId={gameId}
            />
          </Tab>
          <Tab eventKey="chat" title={chatTitle}>
            <EventChat eventId={eventId} uid={uid} posts={posts} />
          </Tab>
          <Tab eventKey="scoreboard" title="Scorecards">
            <DisplayLeaderboard
              bg="white"
              textColor="dark"
              size="md"
              bgImage=""
              bgImageOpacity={1}
              title={eventName}
              subtitle=""
              eventId={eventId}
              scoringOrderGolf={scoringOrderGolf}
              isEventComplete={isEventComplete}
            ></DisplayLeaderboard>
          </Tab>
          <Tab eventKey="players" title="Leaderboard">
            {scoringOrderGolf !== null ? (
              <EventSection
                bg="white"
                textColor="dark"
                size="md"
                bgImage=""
                bgImageOpacity={1}
                title={eventName}
                subtitle=""
                eventId={eventId}
                scoringOrderGolf={scoringOrderGolf}
                isEventComplete={isEventComplete}
              />
            ) : (
              <div>Loading...</div>
            )}
          </Tab>
        </Tabs>
      </Container>

      <EventSideGameSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Side Games"
        subtitle=""
        eventId={eventId}
      />
    </>
  );
}

export default EventPage;
