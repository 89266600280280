import React from 'react'
import { Container, ProgressBar } from 'react-bootstrap'

function EventCreateFormFooter({ step, numSteps }) {
    return (
        <>
            <Container className="text-center">
                <p>Step {step} of {numSteps}</p>
            </Container>
            <ProgressBar now={(step / numSteps) * 100} />
        </>
    )
}

export default EventCreateFormFooter