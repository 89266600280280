import React from 'react';
import { Button, ListGroup } from 'react-bootstrap';

const ScorecardOrderListItem = ({ item, index, moveItemUp, moveItemDown, isUpdating, sortedItems }) => {
    return (
        <ListGroup.Item key={item.id} className="d-flex justify-content-between align-items-center">
            {item.event_entries.users_public.name}
            <div className="ms-auto">
                <Button
                    variant="secondary"
                    size="sm"
                    onClick={(event) => moveItemUp(index, event)}
                    disabled={index === 0 || isUpdating}
                    className="me-2 custom-button"
                >
                    ↑ 
                </Button>
                <Button
                    variant="secondary"
                    size="sm"
                    onClick={(event) => moveItemDown(index, event)}
                    disabled={index === sortedItems.length - 1 || isUpdating}
                    className="custom-button"
                >
                    ↓
                </Button>
            </div>
        </ListGroup.Item>
    );
};

export default ScorecardOrderListItem;