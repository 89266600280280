import React from "react";
import Alert from "react-bootstrap/Alert";

function EventCreateFormAlertNoName() {
  return (
    <Alert variant="danger" className="mt-2">
      <p className="text-center mb-0">Please enter an event name to continue</p>
    </Alert>
  );
}

export default EventCreateFormAlertNoName;
