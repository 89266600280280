import React from 'react'
import { Table } from 'react-bootstrap'
import ViewScorecardHeaderRow from './ViewScorecardHeaderRow'
import ViewScorecardRow from './ViewScorecardRow'
import './EventManageCardsFullCardsTable.css'; // Import the custom CSS file

function EventManageCardsFullCardsTable({card, isCardComplete, eventId}) {
    const numHoles = 20
    return (
        <Table size='sm' style={{background: isCardComplete ? '#DFF0D8' : "#FFFFFF"}} className='mx-0 my-0 w-100' responsive>
            <thead style={{background:"#2D083F", color:"white"}}>
                <ViewScorecardHeaderRow numHoles={numHoles}/>
            </thead>
            <tbody>
            {card.group_scorecard_players.map((player, index) => (
                <ViewScorecardRow player={player} key={index} isCardComplete={isCardComplete} event={eventId}/>    
            ))}
            </tbody>
        </Table>
  )
}

export default EventManageCardsFullCardsTable