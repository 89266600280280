import React from "react";
import { useUpcomingEvents } from "../util/db";
import FormAlert from "./FormAlert";
import EventsTable from "./EventsTable";

function EventsPageUpcoming() {
  const {
    data: items,
    status: itemsStatus,
    error: itemsError,
  } = useUpcomingEvents();

  return (
    <>
      {itemsError && (
        <div className="mb-3">
          <FormAlert type="error" message={itemsError.message} />
        </div>
      )}

      {itemsStatus !== "loading" && items && items.length > 0 && (
        <>
          <EventsTable events={items} />
        </>
      )}

      {itemsStatus !== "loading" && items && items.length === 0 && (
        <>
          {/* centered text */}
          <div className="text-center mt-3">
            There are no upcoming events. Please check back soon
          </div>
        </>
      )}
    </>
  );
}

export default EventsPageUpcoming;
