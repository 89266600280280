import React from 'react'
import Button from 'react-bootstrap/Button'
import Badge from 'react-bootstrap/Badge'
import './ScorecardThrowScoreBox.css';

function ScorecardThrowScoreBox({backgroundColor,textColor,thisThrowScore,gameId}) {

    let mainText = ""
    let badgeText =""

    if(gameId === 2){//if bags
        if(thisThrowScore === "0") {
            mainText = '🚫'
            badgeText="Off"
        }else if(thisThrowScore === "1") {
            mainText = '📥'
            badgeText="On"
        }else if(thisThrowScore === "2") {
            mainText = '✔️'
            badgeText="In"
        }else if(thisThrowScore === '?') {
            mainText = '?'
            badgeText=""
        }
    }else{//not bags (disc putting)
        if(thisThrowScore === "0") {
            mainText = '🚫'
            badgeText="Miss"
        }else if(thisThrowScore === "1") {
            mainText = '⛓️'
            badgeText="Hit"
        }else if(thisThrowScore === "2") {
            mainText = '✔️'
            badgeText="Make"
        }else if(thisThrowScore === '?') {
            mainText = '?'
            badgeText=""
        }
    }
    
    const style = {
        // borderRadius: '15px',
        // padding: '30px',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: '30px',
        paddingBottom: '30px',
        backgroundColor: backgroundColor,
        color: textColor,
        fontSize: '30px',
        fontWeight: 'bold',
        border: 'none',
        fontStyle:'italic',
    }

    const badgeStyle = {
        position: 'absolute',
        bottom: '15px', // Move the badge up
        left: '50%',
        transform: 'translateX(-50%)',
        backgroundColor: 'blue',
        color: 'white',
        borderRadius: '50%',
        padding: '5px',
        fontSize: '12px',
    };
    

    return (
        <div className="no-outline" style={{ position: 'relative', display: 'inline-block' }}>
            <Button style={style} className="no-outline">
                {mainText}
            </Button>
            {badgeText && (
                <Badge style={badgeStyle}>
                    {badgeText}
                </Badge>
            )}
        </div>
    )
}

export default ScorecardThrowScoreBox