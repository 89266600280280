import React from 'react'
import { Card, Row, Col } from 'react-bootstrap'
import EventManageCardConfirmDeleteModal from './EventManageCardConfirmDeleteModal'
import { Link } from '../util/router'

function EventManageCardsFullCardFooter({isCardComplete, onUnlockCard, onLockCard, card}) {
  return (
    <Card.Footer style={{backgroundColor:'#2d083F'}}>
        <Row>
            <Col>
                {/* //if card is complete show lock else show unlock */}
                {isCardComplete ? (
                    <div style={{cursor:'pointer'}} onClick={onUnlockCard}>🔐</div>
                ):(
                    <div style={{cursor:'pointer'}} onClick={onLockCard}>🗝️</div>
                )}
            </Col>
            <Col style={{display:'flex', justifyContent:'center'}}>
                <Link to={`scorecard?scorecard=${card.id}`}>
                    👀
                </Link>
            </Col>
            <Col>
                <EventManageCardConfirmDeleteModal 
                    buttonText={'🗑️'}
                    heading={'Delete Card'}
                    bodyText={'Are you sure you want to delete this card?'}
                    cancelText={'Cancel'}
                    confirmText={'Delete'}
                    card={card}
                />
            </Col>
        </Row>
    </Card.Footer>
  )
}

export default EventManageCardsFullCardFooter