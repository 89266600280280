import React from "react";
import Alert from "react-bootstrap/Alert";

function EventCreateFormAlertNoDivisions() {
  return (
    <Alert variant="danger" className="mt-2">
      <p className="text-center mb-0">
        Please choose divisions for this event to continue
      </p>
    </Alert>
  );
}

export default EventCreateFormAlertNoDivisions;
