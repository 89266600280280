import React from "react";
import Form from "react-bootstrap/Form";
import SectionHeader from "./SectionHeader";

function EventCreateFormStep1({
  games,
  formName,
  formDate,
  formGame,
  formIsReplay,
  formIsGolfScoring,
  handleFormNameChange,
  handleFormDateChange,
  handleFormGameChange,
  handleFormIsReplayChange,
  handleFormIsGolfScoringChange,
}) {
  return (
    <>
      <SectionHeader
        title={"Create Event"}
        // subtitle={props.subtitle}
        size={1}
        spaced={true}
        className="text-center mt-3 mb-3"
      />
      <Form.Group className="mb-3" controlId="name">
        <Form.Label>Event Name</Form.Label>
        <Form.Control
          type="text"
          onChange={handleFormNameChange}
          required
          value={formName}
        />
        {/* <Form.Text className="text-muted">
                We'll never share your email with anyone else.
                </Form.Text> */}
      </Form.Group>

      <Form.Group className="mb-3" controlId="date">
        <Form.Label>Date</Form.Label>
        <Form.Control
          type="date"
          placeholder="date"
          onChange={handleFormDateChange}
          required
          value={formDate}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="game">
        <Form.Label>Game</Form.Label>
        <Form.Control
          as="select"
          onChange={handleFormGameChange}
          value={formGame}
        >
          {games &&
            games.map((game) => (
              <option key={game.id} value={game.id}>
                {game.name}
              </option>
            ))}
        </Form.Control>
      </Form.Group>

      {/* <Form.Group className="mb-3" controlId="isReplay">
                <Form.Check type="switch" label="Allow Replays?" onChange={handleFormIsReplayChange} value={formIsReplay} />
            </Form.Group> */}

      <Form.Group className="mb-3" controlId="isGolfScoring">
        <Form.Check
          type="switch"
          label="Golf Scoring?"
          onChange={handleFormIsGolfScoringChange}
          value={formIsGolfScoring}
        />
      </Form.Group>
    </>
  );
}

export default EventCreateFormStep1;
