import React from 'react';
import ScorecardThrowRowTotalScoreBox from './ScorecardThrowRowTotalScoreBox';



function ScorecardThrowsRowScoreboxTd({ isUpdatingScore, isComplete, thisScore, hole_scores_id, dbResponseScore, onClickScore, onClickLockedScore, isBonusPoint}) {

  //if thisscore ==5, true, else false
  // const isBonusPoint = false

  // const [isBonusPoint,setIsBonusPoint] = useState(thisScore === 5 ? true : false)

  // useEffect(() => {
  //   setIsBonusPoint(thisScore === 5 ? true : false)
  // }, [thisScore])

  const tdStyle = {
      textAlign: 'center',
      width: '30px',
      position: 'relative', // Ensure relative positioning for absolute child
    };
  
    const bonusPointStyle = {
      position: 'absolute',
      left: '5px', // Adjust as needed
      top: '50%',
      transform: 'translateY(-50%)',
      color: '#5B107E',
      fontWeight: 'bold',
      fontSize: '12px',
    };

  return (
    isComplete
        ?
        <>
          <td style={tdStyle} onClick={()=>onClickLockedScore()}>
            {isBonusPoint && <div style={bonusPointStyle}>+1</div>}
            <ScorecardThrowRowTotalScoreBox backgroundColor={'#ffffff'} textColor={'#2D083F'} text={thisScore} />
          </td>
        </>
        :      
          thisScore === null || thisScore === undefined //this means there was no score entered yet
            ? 
              hole_scores_id !== null //if hole_scores_id is not null, then the hole is present in the database. this always should be true. Can remove when promise issue is fixed. it might be fixed already, unsure.
              ? 
              <>
                <td style={tdStyle} onClick={()=>onClickScore()}>
                  <ScorecardThrowRowTotalScoreBox backgroundColor={'#ffffff'} textColor={'#BFBFBF'} text={'?'} />
                </td>
              </>
              : //hole_scores_id is null. This is an error state
              <>
                <td style={tdStyle} onClick={()=>onClickScore()}>
                {isBonusPoint && <div style={bonusPointStyle}>+1</div>}
                  <ScorecardThrowRowTotalScoreBox backgroundColor={'red'} textColor={'#d9e3ff'} text={''} />
                </td>
              </>
            : //thisScore is not null or undefined. This means that there was already a score entered
              <>
                {dbResponseScore !== null ? //if dbResponseScore is not null, then the score was saved to the database
                  <>
                  <td style={tdStyle} onClick={()=>onClickScore()}>
                  {isBonusPoint && <div style={bonusPointStyle}>+1</div>}
                    <ScorecardThrowRowTotalScoreBox backgroundColor={'#ffffff'} textColor={'#2D083F'} text={thisScore} />
                  </td>
                  </>
                : //black text if there is already a score in the db. and gray if there is not yet
                
                  <>
                  {isUpdatingScore
                   ?
                    <>
                    <td style={tdStyle} onClick={()=>onClickScore()}>
                    {isBonusPoint && <div style={bonusPointStyle}>+1</div>}
                      <ScorecardThrowRowTotalScoreBox backgroundColor={'#ffffff'} textColor={'#c0b4c5'} text={thisScore} />
                    </td>
                    </>
                   :
                    <>
                    
                    {/* casting thisscore to string to prevent warning */}
                    <td style={tdStyle} onClick={()=>onClickScore()}>
                      <ScorecardThrowRowTotalScoreBox backgroundColor={'#ffffff'} textColor={'#2D083F'} text={thisScore.toString()} />
                    </td>
                    </>
                   }
                  </>
                }
              </>     
  );
}

export default ScorecardThrowsRowScoreboxTd;