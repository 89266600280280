import React from 'react'
import Button from 'react-bootstrap/Button'

function ScorecardThrowRowTotalScoreBox({backgroundColor,textColor,text}) {
    
    const style = {
        // borderRadius: '15px',
        padding: '30px',
        backgroundColor: backgroundColor,
        color: textColor,
        fontSize: '30px',
        fontWeight: 'bold',
        border: 'none',
        fontStyle:'italic'
    }

    return (
        <Button style={style}>
            {text}
        </Button>
    )
}

export default ScorecardThrowRowTotalScoreBox